.postion-table-accordion {
  display: flex;
  margin-top: 100px;
}

.table-styles {
  width: 60vw !important;
  height: 80vh !important;
  margin-left: 60px !important;
}

.table-styles-open-layout {
  width: 45vw !important;
  height: 80vh !important;
  margin-left: 60px !important;
}

.accordion-actions-styles {
  margin-left: 20px;
  padding-right: 80px;
  max-height: 500px;
  width: auto;
  height: auto;
  overflow: scroll;
  overflow-x: hidden;
}

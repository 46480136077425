.create-site-users-container {
  display: flex;
  margin-top: 24px !important;
}

.input-create-loc-users-form {
  width: 307px;
  max-width: calc(100% - 20px);
  margin-bottom: 24px;
}

.create-info-loc-user-names {
  width: 307px;
  max-width: calc(100% - 20px);
  margin-right: 16px;
}

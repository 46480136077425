.table-styles-data {
  height: 70vh !important;
  background-color: $contrast_inarix !important;
  margin-left: 35px !important;
  margin-right: 35px !important;
  padding-bottom: 10px !important;
}
.data-date-range-picker {
  margin-top: 8px;
}

.data-extra-filters {
  margin-top: 8px;
  margin-left: 10px;
}

.panel-options {
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.options-left-data-panel {
  display: flex;
  flex-wrap: wrap;
}

.options-right-data-panel {
  margin-top: 8px;
  margin-left: 10px;
  display: flex;
}

.data-table-sidebar-styles {
  position: absolute;
  top: 0;
}

.fleet-table-container-data {
  margin-top: 100px;
  height: 80vh;
  background-color: $background_Components;
}

//SB

.title-container-sb {
  display: flex;
  padding-left: 3%;
  width: 350px;
  margin-top: 10px;
}

.by-crop {
  padding-top: 5%;
  padding-left: 8%;
  padding-right: 8%;
  border-top: 1px solid #e4e4e5;
}

.by-site {
  padding-left: 8%;
  padding-right: 8%;
}

.by-action {
  margin-top: -20px;
  padding-left: 8%;
  padding-right: 8%;
}

.titles-sb-features {
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}
.by-site-container {
  margin-top: 5px;
}

.autocomplete-sb-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;
}

.button-position-sb-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 20px;
  width: 100%;
  margin-bottom: 12px;
  border-top: 1px solid #e4e4e5;
}

.buttons-actions-data-sb {
  padding-left: 8%;
  padding-right: 5%;
}

.info-modal-export {
  font-size: 10px;
  margin-left: 33px;
  margin-top: -15px;
}

.modal-export-download-text {
  font-size: 14px;
  padding-right: 4px;
  margin-bottom: 25px;
}
.modal-export-email-text {
  font-size: 14px;
  margin-bottom: 25px;
  padding-right: 2px;
}

.loader-style-data-export {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-top: -25px;
}

//Success body data export

.position-buttons-export {
  display: flex;
  justify-content: space-around;
  margin-top: -3%;

  @media (max-height: 720px) {
    padding-bottom: 30px;
  }
}

.loader-style-data-export-body-done {
  display: flex;
  justify-content: center;
  margin-top: -15px;
  flex-direction: column;
}
#success_tic-data .page-body-data {
  max-width: 600px;
  height: 50px;
  margin: 10% auto;
  border-radius: 4px;
}
#success_tic-data .page-body-data .head {
  text-align: center;
}

#success_tic-data .close {
  position: absolute;
  width: 400;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 24px 24px;
}
#success_tic-data .checkmark-circle-data {
  margin-top: -6px;
  width: 100px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.h1-export-data-modal {
  height: 30px;
}

.checkmark-circle-data .background-data {
  left: 33%;
  margin-top: 2px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #4caf50;
  position: absolute;
}
#success_tic-data .checkmark-circle-data .checkmark-data {
  border-radius: 5px;
}
#success_tic-data .checkmark-circle-data .checkmark-data.draw-data:after {
  height: 37px;
  width: 17.5px;
  -webkit-animation-delay: 600ms;
  -moz-animation-delay: 300ms;
  animation-delay: 600ms;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark-data;
  -moz-animation-name: checkmark-data;
  animation-name: checkmark-data;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#success_tic-data .checkmark-circle-data .checkmark-data:after {
  opacity: 1;
  height: 37px;
  width: 17.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 7px solid #fff;
  border-top: 7px solid #fff;
  border-radius: 2.5px !important;
  content: "";
  left: 37%;
  top: 31px;
  position: absolute;
}

@-webkit-keyframes checkmark-data {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
}

@-moz-keyframes checkmark-data {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
}

@keyframes checkmark-data {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 37px;
    width: 17.5px;
    opacity: 1;
  }
}

.input-search-data {
  height: 20px !important;
}

.button-containers-columns {
  display: flex;
  justify-content: space-around;
  padding-bottom: 7px;
}

.columns-container-switches {
  width: 351px;
  height: 400px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 10px;
}

.switches-data-container {
  height: 295px;
  overflow: scroll;
  padding-top: 15px;
  padding-left: 24px;
  padding-right: 24px;
}

.new-buttons-switches {
  display: flex;
  justify-content: flex-end;
}

/**
 * @author David Roman <david@inarix.com>
 * @components css
 * @desc Created on 2021-09-22 1:40:31 pm
 * @copyright Inarix
 */

$main_inarix: #4676ee;
$dark_inarix: #1d4dba;
$light_inarix: #4ba6ee;
$contrast_inarix: #ffffff;
$p4: #f8fbff;
$p8: #eff6fe;
$p12: #e8f2fd;
$p30: #c6ddfb;
$p30_ripple: #c6ddfb;
$p50: #a1c6f8;
$p160: #4d1916;
$p190: #f8eae9;
$action_active_inarix: #87858a;
$action_hover_inarix: #f6f6f6;
$action_selected_inarix: #ededee;
$actions_disabled_inarix: #c5c4c7;
$actions_disabled_background_inarix: #e4e4e5;
$action_focus_inarix: #e4e4e5;

$primary_text_inarix: #3c3a40;
$secondary_text_inarix: #79777d;
$disabled_inarix: #aba9ad;
$divider_inarix: #e4e4e5;
$outline_border_inarix: #ffffff;
$standard_input_line_inarix: #ffffff;
$backdrop_overlay_inarix: #908e93;
$rating_active_inarix: #f5b63f;
$snackbar_background_inarix: #211d24;
$tables_action_color: #ffe1ec;
$layout_background: rgb(33, 29, 38);
$cancel_button: #facea8;
$confirm_button: #e0e0e0;
$background_Components: #fafafa;
$map_background_color: #6fbdd5;
$modal_danger_color: #d92d20;
$disabled_button_layout: #87858a;
$hover_layout_color: #322f37;
$box_shadow_components: rgba(0, 0, 0, 0.267);
$notification_color: #ed6c03;
$locationWithActivityBackground: #ffca28;
$locationWithoutActivityBackground: #cfd8dc;

@import "./components/login";
@import "./components/authentication/resetPassword";
@import "./components/base/base";
@import "./components/newNav";
@import "./InarixStyles.module.scss";
@import "./components/users/table";
@import "./components/modalsStyles";
@import "./components/successModal";
@import "./components/devices/devices";
@import "./components/actions/actions";
@import "./components/projects/projects";
@import "./components/dashboard/dashboard";
@import "./components/locations/createLocations";
@import "./components/locations/createLocationsUsers";

// MAP

@import "./components/map/createEditLocation";
@import "./components/map/overviewSideBar";
@import "./components/map/searchSideBars";
@import "./components/map/icons_styles";
@import "./components/map/overview";
@import "./components/map/fleet";

// DATA

@import "./components/data/dataCarouselPhotos";
@import "./components/data/exportModal";
@import "./components/data/data";
@import "./components/data/dataSampleView";
